<template>
    <path d="M240,409.6V100L97.1,159.5C105.5,275.7,162.3,362.1,240,409.6z M450.5,83.7l-192-80c-11.8-4.9-25.1-4.9-36.9,0l-192,80  C11.7,91.1,0,108.6,0,128c0,198.5,114.5,335.7,221.5,380.3c11.8,4.9,25.1,4.9,36.9,0C344.1,472.6,480,349.3,480,128  C480,108.6,468.3,91.1,450.5,83.7z M240,464C142.5,423.4,48,297.3,48,128l192-80l192,80C432,301.8,333.6,425,240,464z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 480, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
